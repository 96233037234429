<template>
  <Layout>
    <div class="tcf20container regcontainer">
      <div class="logo" />
      <img
        v-if="orientation !== 'landscape'"
        class="header"
        alt="Talking Tom and Friends"
        :src="require('../../../assets/images/pp_friends.jpg')"
      >
      <div class="text notitle">
        {{ content.pipl_warning }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_pp')"
          :text="content.pipl_warning1"
        />
        {{ content.pipl_warning2 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_childrens_pp')"
          :text="content.pipl_warning3"
        />
        {{ content.pipl_warning4 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_eula')"
          :text="content.pipl_warning5"
        />
        {{ content.pipl_warning6 }}
        <ExternalLink
          :url="$compliance.getPolicyUrl('pipl_3rdparty_list')"
          :text="content.pipl_warning7"
        />
        {{ content.pipl_warning8 }}
      </div>
      <Footer
        module="pipl"
        screen="warning"
      />
    </div>
  </Layout>
</template>

<script>
import Footer from '@components/Footer.vue'
import ExternalLink from '@components/ExternalLink.vue'

export default {
  metaInfo() {
    return {
      title: 'Outfit7 Compliance module',
      meta: [
        {
          key: 'description',
          name: 'description',
          content: 'Outfit7 Compliance module',
        },
      ],
    }
  },
  components: {
    Footer,
    ExternalLink,
  },
  data() {
    return {
      content: {},
      orientation: '',
      lang: '',
    }
  },
  created() {
    this.content = this.$compliance.getLocalisedContent()
  },
  mounted() {
    this.orientation = this.$compliance.screenOrientation
    this.lang = this.$compliance.lang
    this.$compliance.forceRedraw()
  },
}
</script>

<style lang="scss">
.tcf20container {
  position: relative;
  padding: 10px 0 10px 0;
  background: #fff;
  width: 100%;

  .logo {
    width: 20%;
    height: 40px;
    max-width: 130px;
    margin: 20px auto 0 auto;
    background-image: url('../../../assets/images/logo.jpg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .header {
    width: calc(100% - 60px);
    height: auto;
    margin: 0 30px;
  }
  .v-lazy-image {
    filter: blur(10px);
    transition: filter 0.7s;
  }
  .v-lazy-image-loaded {
    filter: blur(0);
  }
  .title {
    text-transform: uppercase;
    color: #4f5c68;
    font-size: 1.8em;
    font-weight: 800;
    line-height: 1em;
    text-align: center;
    width: 100%;
    display: inline-block;
    margin: 10px auto 10px auto;

    @media screen and (min-width: 768px) {
      font-size: 3.2em;
    }
    @media screen and (min-width: 1024px) {
      font-size: 5em;
    }
  }
  .text {
    padding: 0 10px 130px 10px;
    font-size: 1em;
    color: #4c5d6a;
    text-align: center;
    &.noheader {
      margin: 0;
    }
    &.notitle {
      margin: 20px 0 0 0;
    }
    span {
      color: #688194;
      text-decoration: underline;
    }
    @media screen and (min-width: 768px) {
      font-size: 1.8em;
      padding: 0 10px 190px 10px;
    }
    @media screen and (min-width: 1024px) {
      font-size: 2.1em;
      padding: 0 10px 200px 10px;
    }
  }
}
.absolutefooter .tcf20container {
  height: 100%;
}
</style>
